/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { VendorCompanyAddressView } from '../models/vendor-company-address-view';
import { VendorContactInfoRes } from '../models/vendor-contact-info-res';
import { VendorDocumentRes } from '../models/vendor-document-res';
import { VendorProductRes } from '../models/vendor-product-res';
import { VendorProfileRes } from '../models/vendor-profile-res';

@Injectable({ providedIn: 'root' })
export class VendorProfileService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiVendorProfileByIdGet()` */
  static readonly ApiVendorProfileByIdGetPath = '/api/vendor-profile/by-id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorProfileByIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorProfileByIdGet$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VendorProfileRes>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileService.ApiVendorProfileByIdGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VendorProfileRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorProfileByIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorProfileByIdGet(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<VendorProfileRes> {
    return this.apiVendorProfileByIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<VendorProfileRes>): VendorProfileRes => r.body)
    );
  }

  /** Path part for operation `apiVendorProfileByAccountIdGet()` */
  static readonly ApiVendorProfileByAccountIdGetPath = '/api/vendor-profile/by-account-id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorProfileByAccountIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorProfileByAccountIdGet$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VendorProfileRes>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileService.ApiVendorProfileByAccountIdGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VendorProfileRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorProfileByAccountIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorProfileByAccountIdGet(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<VendorProfileRes> {
    return this.apiVendorProfileByAccountIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<VendorProfileRes>): VendorProfileRes => r.body)
    );
  }

  /** Path part for operation `apiVendorProfileProfileGet()` */
  static readonly ApiVendorProfileProfileGetPath = '/api/vendor-profile/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorProfileProfileGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorProfileProfileGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VendorProfileRes>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileService.ApiVendorProfileProfileGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VendorProfileRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorProfileProfileGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorProfileProfileGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<VendorProfileRes> {
    return this.apiVendorProfileProfileGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<VendorProfileRes>): VendorProfileRes => r.body)
    );
  }

  /** Path part for operation `apiVendorProfileAddressesGet()` */
  static readonly ApiVendorProfileAddressesGetPath = '/api/vendor-profile/addresses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorProfileAddressesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorProfileAddressesGet$Response(
    params?: {
      profileId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VendorCompanyAddressView>>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileService.ApiVendorProfileAddressesGetPath, 'get');
    if (params) {
      rb.query('profileId', params.profileId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VendorCompanyAddressView>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorProfileAddressesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorProfileAddressesGet(
    params?: {
      profileId?: string;
    },
    context?: HttpContext
  ): Observable<Array<VendorCompanyAddressView>> {
    return this.apiVendorProfileAddressesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VendorCompanyAddressView>>): Array<VendorCompanyAddressView> => r.body)
    );
  }

  /** Path part for operation `apiVendorProfileContactsGet()` */
  static readonly ApiVendorProfileContactsGetPath = '/api/vendor-profile/contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorProfileContactsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorProfileContactsGet$Response(
    params?: {
      profileId?: string;
      contactTypeCode?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VendorContactInfoRes>>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileService.ApiVendorProfileContactsGetPath, 'get');
    if (params) {
      rb.query('profileId', params.profileId, {});
      rb.query('contactTypeCode', params.contactTypeCode, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VendorContactInfoRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorProfileContactsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorProfileContactsGet(
    params?: {
      profileId?: string;
      contactTypeCode?: string;
    },
    context?: HttpContext
  ): Observable<Array<VendorContactInfoRes>> {
    return this.apiVendorProfileContactsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VendorContactInfoRes>>): Array<VendorContactInfoRes> => r.body)
    );
  }

  /** Path part for operation `apiVendorProfileDocumentsGet()` */
  static readonly ApiVendorProfileDocumentsGetPath = '/api/vendor-profile/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorProfileDocumentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorProfileDocumentsGet$Response(
    params?: {
      profileId?: string;
      isConfidential?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VendorDocumentRes>>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileService.ApiVendorProfileDocumentsGetPath, 'get');
    if (params) {
      rb.query('profileId', params.profileId, {});
      rb.query('isConfidential', params.isConfidential, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VendorDocumentRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorProfileDocumentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorProfileDocumentsGet(
    params?: {
      profileId?: string;
      isConfidential?: boolean;
    },
    context?: HttpContext
  ): Observable<Array<VendorDocumentRes>> {
    return this.apiVendorProfileDocumentsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VendorDocumentRes>>): Array<VendorDocumentRes> => r.body)
    );
  }

  /** Path part for operation `apiVendorProfileProductServicesGet()` */
  static readonly ApiVendorProfileProductServicesGetPath = '/api/vendor-profile/product-services';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorProfileProductServicesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorProfileProductServicesGet$Response(
    params?: {
      profileId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VendorProductRes>>> {
    const rb = new RequestBuilder(this.rootUrl, VendorProfileService.ApiVendorProfileProductServicesGetPath, 'get');
    if (params) {
      rb.query('profileId', params.profileId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VendorProductRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorProfileProductServicesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorProfileProductServicesGet(
    params?: {
      profileId?: string;
    },
    context?: HttpContext
  ): Observable<Array<VendorProductRes>> {
    return this.apiVendorProfileProductServicesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VendorProductRes>>): Array<VendorProductRes> => r.body)
    );
  }

}
