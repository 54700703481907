/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { InboxCountRes } from '../models/inbox-count-res';
import { InboxVendorItemRes } from '../models/inbox-vendor-item-res';
import { InboxVendorListRes } from '../models/inbox-vendor-list-res';
import { InboxVendorOptDataRes } from '../models/inbox-vendor-opt-data-res';
import { InboxVendorReq } from '../models/inbox-vendor-req';

@Injectable({ providedIn: 'root' })
export class InboxService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiInboxMyTaskQueryPost()` */
  static readonly ApiInboxMyTaskQueryPostPath = '/api/inbox/my-task/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInboxMyTaskQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInboxMyTaskQueryPost$Response(
    params?: {
      body?: InboxVendorReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<InboxVendorListRes>> {
    const rb = new RequestBuilder(this.rootUrl, InboxService.ApiInboxMyTaskQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InboxVendorListRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInboxMyTaskQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInboxMyTaskQueryPost(
    params?: {
      body?: InboxVendorReq
    },
    context?: HttpContext
  ): Observable<InboxVendorListRes> {
    return this.apiInboxMyTaskQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<InboxVendorListRes>): InboxVendorListRes => r.body)
    );
  }

  /** Path part for operation `apiInboxMyTaskGet()` */
  static readonly ApiInboxMyTaskGetPath = '/api/inbox/my-task';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInboxMyTaskGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInboxMyTaskGet$Response(
    params?: {
      inboxVendorId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<InboxVendorItemRes>> {
    const rb = new RequestBuilder(this.rootUrl, InboxService.ApiInboxMyTaskGetPath, 'get');
    if (params) {
      rb.query('inboxVendorId', params.inboxVendorId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InboxVendorItemRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInboxMyTaskGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInboxMyTaskGet(
    params?: {
      inboxVendorId?: number;
    },
    context?: HttpContext
  ): Observable<InboxVendorItemRes> {
    return this.apiInboxMyTaskGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<InboxVendorItemRes>): InboxVendorItemRes => r.body)
    );
  }

  /** Path part for operation `apiInboxMyRequestQueryPost()` */
  static readonly ApiInboxMyRequestQueryPostPath = '/api/inbox/my-request/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInboxMyRequestQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInboxMyRequestQueryPost$Response(
    params?: {
      body?: InboxVendorReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<InboxVendorListRes>> {
    const rb = new RequestBuilder(this.rootUrl, InboxService.ApiInboxMyRequestQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InboxVendorListRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInboxMyRequestQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInboxMyRequestQueryPost(
    params?: {
      body?: InboxVendorReq
    },
    context?: HttpContext
  ): Observable<InboxVendorListRes> {
    return this.apiInboxMyRequestQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<InboxVendorListRes>): InboxVendorListRes => r.body)
    );
  }

  /** Path part for operation `apiInboxMyRequestGet()` */
  static readonly ApiInboxMyRequestGetPath = '/api/inbox/my-request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInboxMyRequestGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInboxMyRequestGet$Response(
    params?: {
      processKey?: string;
      taskKey?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<InboxVendorItemRes>> {
    const rb = new RequestBuilder(this.rootUrl, InboxService.ApiInboxMyRequestGetPath, 'get');
    if (params) {
      rb.query('processKey', params.processKey, {});
      rb.query('taskKey', params.taskKey, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InboxVendorItemRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInboxMyRequestGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInboxMyRequestGet(
    params?: {
      processKey?: string;
      taskKey?: string;
    },
    context?: HttpContext
  ): Observable<InboxVendorItemRes> {
    return this.apiInboxMyRequestGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<InboxVendorItemRes>): InboxVendorItemRes => r.body)
    );
  }

  /** Path part for operation `apiInboxOptDataGet()` */
  static readonly ApiInboxOptDataGetPath = '/api/inbox/opt-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInboxOptDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInboxOptDataGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<InboxVendorOptDataRes>> {
    const rb = new RequestBuilder(this.rootUrl, InboxService.ApiInboxOptDataGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InboxVendorOptDataRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInboxOptDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInboxOptDataGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<InboxVendorOptDataRes> {
    return this.apiInboxOptDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<InboxVendorOptDataRes>): InboxVendorOptDataRes => r.body)
    );
  }

  /** Path part for operation `apiInboxCounterGet()` */
  static readonly ApiInboxCounterGetPath = '/api/inbox/counter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInboxCounterGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInboxCounterGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<InboxCountRes>> {
    const rb = new RequestBuilder(this.rootUrl, InboxService.ApiInboxCounterGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InboxCountRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInboxCounterGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInboxCounterGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<InboxCountRes> {
    return this.apiInboxCounterGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<InboxCountRes>): InboxCountRes => r.body)
    );
  }

}
