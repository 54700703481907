/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ActivityLogResp } from '../models/activity-log-resp';
import { CcrEditExResp } from '../models/ccr-edit-ex-resp';
import { CcrExOptDataRes } from '../models/ccr-ex-opt-data-res';
import { CcrFileStreamResp } from '../models/ccr-file-stream-resp';
import { CcrhmRequestChatReq } from '../models/ccrhm-request-chat-req';
import { CcrhmRequestChatRes } from '../models/ccrhm-request-chat-res';
import { CcrVendorActionReq } from '../models/ccr-vendor-action-req';

@Injectable({ providedIn: 'root' })
export class CcrRequestService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiCcrRequestGet()` */
  static readonly ApiCcrRequestGetPath = '/api/ccr/request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestGet$Response(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrEditExResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestGetPath, 'get');
    if (params) {
      rb.query('requestId', params.requestId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrEditExResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestGet(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<CcrEditExResp> {
    return this.apiCcrRequestGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrEditExResp>): CcrEditExResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptDataGet()` */
  static readonly ApiCcrRequestOptDataGetPath = '/api/ccr/request/opt-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptDataGet$Response(
    params?: {
      requestId?: string;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrExOptDataRes>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptDataGetPath, 'get');
    if (params) {
      rb.query('requestId', params.requestId, {});
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrExOptDataRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptDataGet(
    params?: {
      requestId?: string;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<CcrExOptDataRes> {
    return this.apiCcrRequestOptDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrExOptDataRes>): CcrExOptDataRes => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestVendorActionPost()` */
  static readonly ApiCcrRequestVendorActionPostPath = '/api/ccr/request/vendor-action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestVendorActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestVendorActionPost$Response(
    params?: {
      body?: CcrVendorActionReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestVendorActionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestVendorActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestVendorActionPost(
    params?: {
      body?: CcrVendorActionReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiCcrRequestVendorActionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestDownloadToPdfGet()` */
  static readonly ApiCcrRequestDownloadToPdfGetPath = '/api/ccr/request/download-to-pdf';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestDownloadToPdfGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestDownloadToPdfGet$Response(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrFileStreamResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestDownloadToPdfGetPath, 'get');
    if (params) {
      rb.query('requestId', params.requestId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrFileStreamResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestDownloadToPdfGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestDownloadToPdfGet(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<CcrFileStreamResp> {
    return this.apiCcrRequestDownloadToPdfGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrFileStreamResp>): CcrFileStreamResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestLogsGet()` */
  static readonly ApiCcrRequestLogsGetPath = '/api/ccr/request/logs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestLogsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestLogsGet$Response(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ActivityLogResp>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestLogsGetPath, 'get');
    if (params) {
      rb.query('requestId', params.requestId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ActivityLogResp>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestLogsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestLogsGet(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<Array<ActivityLogResp>> {
    return this.apiCcrRequestLogsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActivityLogResp>>): Array<ActivityLogResp> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestChatBoxMessagesGet()` */
  static readonly ApiCcrRequestChatBoxMessagesGetPath = '/api/ccr/request/chat-box/messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestChatBoxMessagesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestChatBoxMessagesGet$Response(
    params?: {
      ccrRequestId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CcrhmRequestChatRes>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestChatBoxMessagesGetPath, 'get');
    if (params) {
      rb.query('ccrRequestId', params.ccrRequestId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CcrhmRequestChatRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestChatBoxMessagesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestChatBoxMessagesGet(
    params?: {
      ccrRequestId?: string;
    },
    context?: HttpContext
  ): Observable<Array<CcrhmRequestChatRes>> {
    return this.apiCcrRequestChatBoxMessagesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CcrhmRequestChatRes>>): Array<CcrhmRequestChatRes> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestChatBoxMessagePost()` */
  static readonly ApiCcrRequestChatBoxMessagePostPath = '/api/ccr/request/chat-box/message';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestChatBoxMessagePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestChatBoxMessagePost$Response(
    params?: {
      body?: CcrhmRequestChatReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestChatBoxMessagePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestChatBoxMessagePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestChatBoxMessagePost(
    params?: {
      body?: CcrhmRequestChatReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiCcrRequestChatBoxMessagePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
