import { NgModule } from '@angular/core';
import { SafehtmlPipe } from './safehtml.pipe';


@NgModule({
  declarations: [
    SafehtmlPipe
  ],
  imports: [

  ],
  exports: [
    SafehtmlPipe
  ]
})
export class VnsCommonModule { }
