/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ManualRequestReq } from '../models/manual-request-req';
import { ManualRequestSubmitRes } from '../models/manual-request-submit-res';
import { StepBar } from '../models/step-bar';

@Injectable({ providedIn: 'root' })
export class ManualRequestService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiManualRequestProfileGet()` */
  static readonly ApiManualRequestProfileGetPath = '/api/manual-request/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestProfileGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestProfileGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ManualRequestReq>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestProfileGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManualRequestReq>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestProfileGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestProfileGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<ManualRequestReq> {
    return this.apiManualRequestProfileGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ManualRequestReq>): ManualRequestReq => r.body)
    );
  }

  /** Path part for operation `apiManualRequestStepbarGet()` */
  static readonly ApiManualRequestStepbarGetPath = '/api/manual-request/Stepbar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestStepbarGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestStepbarGet$Response(
    params?: {
      manualRequestId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StepBar>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestStepbarGetPath, 'get');
    if (params) {
      rb.query('manualRequestId', params.manualRequestId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StepBar>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestStepbarGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiManualRequestStepbarGet(
    params?: {
      manualRequestId?: string;
    },
    context?: HttpContext
  ): Observable<StepBar> {
    return this.apiManualRequestStepbarGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<StepBar>): StepBar => r.body)
    );
  }

  /** Path part for operation `apiManualRequestSaveProfilePost()` */
  static readonly ApiManualRequestSaveProfilePostPath = '/api/manual-request/SaveProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiManualRequestSaveProfilePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestSaveProfilePost$Response(
    params?: {
      body?: ManualRequestReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ManualRequestSubmitRes>> {
    const rb = new RequestBuilder(this.rootUrl, ManualRequestService.ApiManualRequestSaveProfilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManualRequestSubmitRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiManualRequestSaveProfilePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiManualRequestSaveProfilePost(
    params?: {
      body?: ManualRequestReq
    },
    context?: HttpContext
  ): Observable<ManualRequestSubmitRes> {
    return this.apiManualRequestSaveProfilePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ManualRequestSubmitRes>): ManualRequestSubmitRes => r.body)
    );
  }

}
