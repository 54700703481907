/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CxAccAndRoleOptDataRes } from '../models/cx-acc-and-role-opt-data-res';
import { CxAccountDto } from '../models/cx-account-dto';
import { CxAccountListRes } from '../models/cx-account-list-res';

@Injectable({ providedIn: 'root' })
export class AccountSettingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiSettingAccountAllGet()` */
  static readonly ApiSettingAccountAllGetPath = '/api/setting/account/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingAccountAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingAccountAllGet$Response(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CxAccountListRes>>> {
    const rb = new RequestBuilder(this.rootUrl, AccountSettingService.ApiSettingAccountAllGetPath, 'get');
    if (params) {
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CxAccountListRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSettingAccountAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingAccountAllGet(
    params?: {
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<CxAccountListRes>> {
    return this.apiSettingAccountAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CxAccountListRes>>): Array<CxAccountListRes> => r.body)
    );
  }

  /** Path part for operation `apiSettingAccountGet()` */
  static readonly ApiSettingAccountGetPath = '/api/setting/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingAccountGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingAccountGet$Response(
    params?: {
      pkCXAccountId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CxAccountDto>> {
    const rb = new RequestBuilder(this.rootUrl, AccountSettingService.ApiSettingAccountGetPath, 'get');
    if (params) {
      rb.query('pkCXAccountId', params.pkCXAccountId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CxAccountDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSettingAccountGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingAccountGet(
    params?: {
      pkCXAccountId?: string;
    },
    context?: HttpContext
  ): Observable<CxAccountDto> {
    return this.apiSettingAccountGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CxAccountDto>): CxAccountDto => r.body)
    );
  }

  /** Path part for operation `apiSettingAccountPut()` */
  static readonly ApiSettingAccountPutPath = '/api/setting/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingAccountPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSettingAccountPut$Response(
    params?: {
      id?: string;
      body?: CxAccountDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccountSettingService.ApiSettingAccountPutPath, 'put');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSettingAccountPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSettingAccountPut(
    params?: {
      id?: string;
      body?: CxAccountDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiSettingAccountPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSettingAccountPost()` */
  static readonly ApiSettingAccountPostPath = '/api/setting/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingAccountPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSettingAccountPost$Response(
    params?: {
      body?: CxAccountDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AccountSettingService.ApiSettingAccountPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSettingAccountPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSettingAccountPost(
    params?: {
      body?: CxAccountDto
    },
    context?: HttpContext
  ): Observable<string> {
    return this.apiSettingAccountPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiSettingAccountDelete()` */
  static readonly ApiSettingAccountDeletePath = '/api/setting/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingAccountDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingAccountDelete$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccountSettingService.ApiSettingAccountDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSettingAccountDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingAccountDelete(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiSettingAccountDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSettingAccountResendResetPasswordEmailPost()` */
  static readonly ApiSettingAccountResendResetPasswordEmailPostPath = '/api/setting/account/resend-reset-password-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingAccountResendResetPasswordEmailPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingAccountResendResetPasswordEmailPost$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccountSettingService.ApiSettingAccountResendResetPasswordEmailPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSettingAccountResendResetPasswordEmailPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingAccountResendResetPasswordEmailPost(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiSettingAccountResendResetPasswordEmailPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSettingAccountOptDataGet()` */
  static readonly ApiSettingAccountOptDataGetPath = '/api/setting/account/opt-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSettingAccountOptDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingAccountOptDataGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CxAccAndRoleOptDataRes>> {
    const rb = new RequestBuilder(this.rootUrl, AccountSettingService.ApiSettingAccountOptDataGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CxAccAndRoleOptDataRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSettingAccountOptDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSettingAccountOptDataGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<CxAccAndRoleOptDataRes> {
    return this.apiSettingAccountOptDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CxAccAndRoleOptDataRes>): CxAccAndRoleOptDataRes => r.body)
    );
  }

}
