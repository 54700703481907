import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { v4 as uuidv4 } from 'uuid';
import { environment } from "../../environments/environment";
import { includes, replace } from "lodash";
import { SpinnerService } from "./services/spinner.service";

@Injectable()
export class SpinInterceptor implements HttpInterceptor {

  private ignoreUrls: string[] = [

  ];

  constructor(private spin: SpinnerService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let uuid: string | null = null;
    const url = replace(request.url, environment.base_url, "");
    const ignore = includes(this.ignoreUrls, url);
    if (!ignore) {
      uuid = uuidv4();
      this.spin.add(uuid);
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (!ignore && uuid) {
          this.spin.stop(uuid);
        }
      })
    );
  }
}
