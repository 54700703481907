/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HcActivityLogRes } from '../models/hc-activity-log-res';
import { HcExRes } from '../models/hc-ex-res';
import { HcExVendorActionReq } from '../models/hc-ex-vendor-action-req';
import { HcExVendorActionSaveReq } from '../models/hc-ex-vendor-action-save-req';
import { HcExVendorCertificateReq } from '../models/hc-ex-vendor-certificate-req';
import { HcExVendorCertificateSaveReq } from '../models/hc-ex-vendor-certificate-save-req';
import { HcExportRes } from '../models/hc-export-res';
import { HcFormStartReq } from '../models/hc-form-start-req';
import { HcInitExRes } from '../models/hc-init-ex-res';
import { HcTrnChatReq } from '../models/hc-trn-chat-req';
import { HcTrnChatRes } from '../models/hc-trn-chat-res';
import { HcTrnProductDto } from '../models/hc-trn-product-dto';
import { HcTrnProductDtoWithoutValidator } from '../models/hc-trn-product-dto-without-validator';
import { HcUnspscAndPsType } from '../models/hc-unspsc-and-ps-type';
import { HcVpInitRes } from '../models/hc-vp-init-res';

@Injectable({ providedIn: 'root' })
export class HealthCheckWorksheetService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiHealthCheckWorksheetDataInitGet()` */
  static readonly ApiHealthCheckWorksheetDataInitGetPath = '/api/HealthCheckWorksheet/data-init';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetDataInitGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetDataInitGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcInitExRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetDataInitGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcInitExRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetDataInitGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetDataInitGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<HcInitExRes> {
    return this.apiHealthCheckWorksheetDataInitGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcInitExRes>): HcInitExRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetDataInitVendorPortfolioGet()` */
  static readonly ApiHealthCheckWorksheetDataInitVendorPortfolioGetPath = '/api/HealthCheckWorksheet/data-init/vendor-portfolio';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetDataInitVendorPortfolioGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetDataInitVendorPortfolioGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcVpInitRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetDataInitVendorPortfolioGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcVpInitRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetDataInitVendorPortfolioGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetDataInitVendorPortfolioGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<HcVpInitRes> {
    return this.apiHealthCheckWorksheetDataInitVendorPortfolioGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcVpInitRes>): HcVpInitRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetGet()` */
  static readonly ApiHealthCheckWorksheetGetPath = '/api/HealthCheckWorksheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetGet$Response(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcExRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetGetPath, 'get');
    if (params) {
      rb.query('trnId', params.trnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcExRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetGet(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<HcExRes> {
    return this.apiHealthCheckWorksheetGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcExRes>): HcExRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetActivityLogsGet()` */
  static readonly ApiHealthCheckWorksheetActivityLogsGetPath = '/api/HealthCheckWorksheet/activity-logs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetActivityLogsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetActivityLogsGet$Response(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcActivityLogRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetActivityLogsGetPath, 'get');
    if (params) {
      rb.query('trnId', params.trnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcActivityLogRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetActivityLogsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetActivityLogsGet(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<Array<HcActivityLogRes>> {
    return this.apiHealthCheckWorksheetActivityLogsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcActivityLogRes>>): Array<HcActivityLogRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetProductsImportByExcelPost()` */
  static readonly ApiHealthCheckWorksheetProductsImportByExcelPostPath = '/api/HealthCheckWorksheet/products/import-by-excel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetProductsImportByExcelPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHealthCheckWorksheetProductsImportByExcelPost$Response(
    params?: {
      body?: {
'workGroupList'?: Array<number>;
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcTrnProductDto>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetProductsImportByExcelPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcTrnProductDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetProductsImportByExcelPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiHealthCheckWorksheetProductsImportByExcelPost(
    params?: {
      body?: {
'workGroupList'?: Array<number>;
}
    },
    context?: HttpContext
  ): Observable<Array<HcTrnProductDto>> {
    return this.apiHealthCheckWorksheetProductsImportByExcelPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcTrnProductDto>>): Array<HcTrnProductDto> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetProductsExportToExcelPost()` */
  static readonly ApiHealthCheckWorksheetProductsExportToExcelPostPath = '/api/HealthCheckWorksheet/products/export-to-excel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetProductsExportToExcelPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetProductsExportToExcelPost$Response(
    params?: {
      hcTrnId?: string;
      body?: Array<HcTrnProductDtoWithoutValidator>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcExportRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetProductsExportToExcelPostPath, 'post');
    if (params) {
      rb.query('hcTrnId', params.hcTrnId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcExportRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetProductsExportToExcelPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetProductsExportToExcelPost(
    params?: {
      hcTrnId?: string;
      body?: Array<HcTrnProductDtoWithoutValidator>
    },
    context?: HttpContext
  ): Observable<HcExportRes> {
    return this.apiHealthCheckWorksheetProductsExportToExcelPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcExportRes>): HcExportRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetFormStartPost()` */
  static readonly ApiHealthCheckWorksheetFormStartPostPath = '/api/HealthCheckWorksheet/form/start';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetFormStartPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetFormStartPost$Response(
    params?: {
      body?: HcFormStartReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetFormStartPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetFormStartPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetFormStartPost(
    params?: {
      body?: HcFormStartReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiHealthCheckWorksheetFormStartPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetVendorActionPost()` */
  static readonly ApiHealthCheckWorksheetVendorActionPostPath = '/api/HealthCheckWorksheet/vendor-action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetVendorActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetVendorActionPost$Response(
    params?: {
      body?: HcExVendorActionReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetVendorActionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetVendorActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetVendorActionPost(
    params?: {
      body?: HcExVendorActionReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiHealthCheckWorksheetVendorActionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetVendorActionSavePost()` */
  static readonly ApiHealthCheckWorksheetVendorActionSavePostPath = '/api/HealthCheckWorksheet/vendor-action/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetVendorActionSavePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetVendorActionSavePost$Response(
    params?: {
      body?: HcExVendorActionSaveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetVendorActionSavePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetVendorActionSavePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetVendorActionSavePost(
    params?: {
      body?: HcExVendorActionSaveReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiHealthCheckWorksheetVendorActionSavePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetCertificateActionPost()` */
  static readonly ApiHealthCheckWorksheetCertificateActionPostPath = '/api/HealthCheckWorksheet/certificate-action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetCertificateActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetCertificateActionPost$Response(
    params?: {
      body?: HcExVendorCertificateReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetCertificateActionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetCertificateActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetCertificateActionPost(
    params?: {
      body?: HcExVendorCertificateReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiHealthCheckWorksheetCertificateActionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetCertificateActionSavePost()` */
  static readonly ApiHealthCheckWorksheetCertificateActionSavePostPath = '/api/HealthCheckWorksheet/certificate-action/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetCertificateActionSavePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetCertificateActionSavePost$Response(
    params?: {
      body?: HcExVendorCertificateSaveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetCertificateActionSavePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetCertificateActionSavePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetCertificateActionSavePost(
    params?: {
      body?: HcExVendorCertificateSaveReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiHealthCheckWorksheetCertificateActionSavePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetOptDataPsTypesPost()` */
  static readonly ApiHealthCheckWorksheetOptDataPsTypesPostPath = '/api/HealthCheckWorksheet/opt-data/ps-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetOptDataPsTypesPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataPsTypesPost$Response(
    params?: {
      workGroupIds?: Array<number>;
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcUnspscAndPsType>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetOptDataPsTypesPostPath, 'post');
    if (params) {
      rb.query('workGroupIds', params.workGroupIds, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcUnspscAndPsType>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetOptDataPsTypesPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetOptDataPsTypesPost(
    params?: {
      workGroupIds?: Array<number>;
      body?: Array<string>
    },
    context?: HttpContext
  ): Observable<Array<HcUnspscAndPsType>> {
    return this.apiHealthCheckWorksheetOptDataPsTypesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcUnspscAndPsType>>): Array<HcUnspscAndPsType> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetChatMessagesGet()` */
  static readonly ApiHealthCheckWorksheetChatMessagesGetPath = '/api/HealthCheckWorksheet/chat/messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetChatMessagesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetChatMessagesGet$Response(
    params?: {
      refId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<HcTrnChatRes>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetChatMessagesGetPath, 'get');
    if (params) {
      rb.query('refId', params.refId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HcTrnChatRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetChatMessagesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetChatMessagesGet(
    params?: {
      refId?: string;
    },
    context?: HttpContext
  ): Observable<Array<HcTrnChatRes>> {
    return this.apiHealthCheckWorksheetChatMessagesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HcTrnChatRes>>): Array<HcTrnChatRes> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetChatMessagesDelete()` */
  static readonly ApiHealthCheckWorksheetChatMessagesDeletePath = '/api/HealthCheckWorksheet/chat/messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetChatMessagesDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetChatMessagesDelete$Response(
    params?: {
      chatId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetChatMessagesDeletePath, 'delete');
    if (params) {
      rb.query('chatId', params.chatId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetChatMessagesDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckWorksheetChatMessagesDelete(
    params?: {
      chatId?: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiHealthCheckWorksheetChatMessagesDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckWorksheetChatMessagePost()` */
  static readonly ApiHealthCheckWorksheetChatMessagePostPath = '/api/HealthCheckWorksheet/chat/message';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckWorksheetChatMessagePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetChatMessagePost$Response(
    params?: {
      body?: HcTrnChatReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckWorksheetService.ApiHealthCheckWorksheetChatMessagePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckWorksheetChatMessagePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckWorksheetChatMessagePost(
    params?: {
      body?: HcTrnChatReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiHealthCheckWorksheetChatMessagePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
