import { environment } from '../../environments/environment';
import { ApiConfiguration } from '../api/api-configuration';
import { AppDataExRes } from '../api/models';

export const appData: AppDataExRes = {};

export function apiBaseUrlGetter() {
  if ((window as any).env) {
    console.log('apiBaseUrlGetter', {
      type: '(window as any).env.API_BASE_URL)',
      value: (window as any).env.API_BASE_URL,
    });
    return (window as any).env.API_BASE_URL;
  }

  if (environment) {
    // console.log("apiBaseUrlGetter", {
    //   type: "environment.base_url",
    //   value: environment.base_url
    // });
    return environment.base_url;
  }

  const apiConfig: ApiConfiguration = new ApiConfiguration();
  console.log('apiBaseUrlGetter', {
    type: 'apiConfig.rootUrl',
    value: apiConfig.rootUrl,
  });

  return apiConfig.rootUrl;
}
