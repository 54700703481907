/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AccountExWithRoleRes } from '../models/account-ex-with-role-res';
import { WorkGroupRes } from '../models/work-group-res';

@Injectable({ providedIn: 'root' })
export class AccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAccountMeGet()` */
  static readonly ApiAccountMeGetPath = '/api/account/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountMeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountMeGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountMeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountMeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountMeGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.apiAccountMeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiAccountGet()` */
  static readonly ApiAccountGetPath = '/api/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccountExWithRoleRes>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountExWithRoleRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<AccountExWithRoleRes> {
    return this.apiAccountGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountExWithRoleRes>): AccountExWithRoleRes => r.body)
    );
  }

  /** Path part for operation `apiAccountWorkgroupAllGet()` */
  static readonly ApiAccountWorkgroupAllGetPath = '/api/account/workgroup/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountWorkgroupAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountWorkgroupAllGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<WorkGroupRes>>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountWorkgroupAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WorkGroupRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountWorkgroupAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountWorkgroupAllGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<WorkGroupRes>> {
    return this.apiAccountWorkgroupAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkGroupRes>>): Array<WorkGroupRes> => r.body)
    );
  }

}
