/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { VocFeedBackOptDataRes } from '../models/voc-feed-back-opt-data-res';
import { VocRequestItem } from '../models/voc-request-item';

@Injectable({ providedIn: 'root' })
export class VocExtRequestService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiVocFeedbackExtRequestGet()` */
  static readonly ApiVocFeedbackExtRequestGetPath = '/api/voc-feedback-ext/request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVocFeedbackExtRequestGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVocFeedbackExtRequestGet$Response(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VocRequestItem>> {
    const rb = new RequestBuilder(this.rootUrl, VocExtRequestService.ApiVocFeedbackExtRequestGetPath, 'get');
    if (params) {
      rb.query('requestId', params.requestId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VocRequestItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVocFeedbackExtRequestGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVocFeedbackExtRequestGet(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<VocRequestItem> {
    return this.apiVocFeedbackExtRequestGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<VocRequestItem>): VocRequestItem => r.body)
    );
  }

  /** Path part for operation `apiVocFeedbackExtRequestNewrequestSubmitPost()` */
  static readonly ApiVocFeedbackExtRequestNewrequestSubmitPostPath = '/api/voc-feedback-ext/request/newrequest/submit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVocFeedbackExtRequestNewrequestSubmitPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVocFeedbackExtRequestNewrequestSubmitPost$Response(
    params?: {
      body?: VocRequestItem
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VocRequestItem>> {
    const rb = new RequestBuilder(this.rootUrl, VocExtRequestService.ApiVocFeedbackExtRequestNewrequestSubmitPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VocRequestItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVocFeedbackExtRequestNewrequestSubmitPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVocFeedbackExtRequestNewrequestSubmitPost(
    params?: {
      body?: VocRequestItem
    },
    context?: HttpContext
  ): Observable<VocRequestItem> {
    return this.apiVocFeedbackExtRequestNewrequestSubmitPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<VocRequestItem>): VocRequestItem => r.body)
    );
  }

  /** Path part for operation `apiVocFeedbackExtRequestOptDataGet()` */
  static readonly ApiVocFeedbackExtRequestOptDataGetPath = '/api/voc-feedback-ext/request/opt-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVocFeedbackExtRequestOptDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVocFeedbackExtRequestOptDataGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VocFeedBackOptDataRes>> {
    const rb = new RequestBuilder(this.rootUrl, VocExtRequestService.ApiVocFeedbackExtRequestOptDataGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VocFeedBackOptDataRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVocFeedbackExtRequestOptDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVocFeedbackExtRequestOptDataGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<VocFeedBackOptDataRes> {
    return this.apiVocFeedbackExtRequestOptDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<VocFeedBackOptDataRes>): VocFeedBackOptDataRes => r.body)
    );
  }

}
