import { NgModule } from '@angular/core';
import { VnsCommonModule } from '../../../../Com.SCGC.PMM.Venus.Web.Cli.Shared/projects/vns-common/src/lib/vns-common.module';


@NgModule({
  declarations: [],
  imports: [],
  exports: [
    VnsCommonModule
  ]
})
export class VnsCommonProviderModule { }
