/*
 * Dynamic configurations using Factory Providers and APP_INITIALIZER
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */

import { APP_INITIALIZER, InjectionToken, NgModule } from '@angular/core';
import {
  IPublicClientApplication,
  PublicClientApplication,
  LogLevel,
  BrowserCacheLocation,
  InteractionType,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppDataService } from './services/app.data.service';
import { SpinInterceptor } from '../utils/spinInterceptor';
import { SpinnerService } from '../utils/services/spinner.service';

const AUTH_CONFIG_URL_TOKEN = new InjectionToken<string>('AUTH_CONFIG_URL');
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function initializerFactory(
  env: AppDataService,
  configUrl: string
): any {
  //console.debug("configUrl", configUrl);
  const promise = new Promise((resolve, reject) => {
    resolve(true);
  });

  return () => promise;
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [],
};

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(
  config: AppDataService
): IPublicClientApplication {

  return new PublicClientApplication({
    auth: config.getSettingObject()?.msal?.auth as any,
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback(logLevel: LogLevel, message: string) {
          if (logLevel == 0) console.log(message);
        },
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(
  config: AppDataService
): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  config.getSettingObject()?.msal?.protectedResourceMaps?.forEach((source) => {
    protectedResourceMap.set(source.endpoint ?? '', source.scopes ?? []);
  });

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(
  config: AppDataService
): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
  };
}

@NgModule({
  declarations: [],
  imports: [MsalModule],
})
export class MsalConfigDynamicModule {
  static forRoot(configUrl: string) {
    return {
      ngModule: MsalConfigDynamicModule,
      providers: [
        AppDataService,
        { provide: AUTH_CONFIG_URL_TOKEN, useValue: configUrl },
        {
          provide: APP_INITIALIZER,
          useFactory: initializerFactory,
          deps: [AppDataService, AUTH_CONFIG_URL_TOKEN],
          multi: true,
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
          deps: [AppDataService],
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory,
          deps: [AppDataService],
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
          deps: [AppDataService],
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SpinInterceptor,
          deps: [SpinnerService],
          multi: true,
        },
      ],
    };
  }
}
