import { Component, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash';
import { en_GB, NzI18nService, th_TH, vi_VN } from 'ng-zorro-antd/i18n';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AccountExWithRoleRes } from './api/models';
import { AccountService } from './api/services';
import { AuthService } from './commons/services/auth.service';
import { appData } from './utils/appInitializer';
import { CURREN_LANG } from './utils/consts/local.storage.key';
import { SpinnerService } from './utils/services/spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {

  private readonly _destroying$ = new Subject<void>();

  isIframe = false;
  loginDisplay = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public translate: TranslateService,
    private authService: AuthService,
    private accountService: AccountService,
    private i18n: NzI18nService,
  ) {
    if (!localStorage.getItem(CURREN_LANG)) {
      localStorage.setItem(CURREN_LANG, 'en');
    }

    const currentLang = localStorage.getItem(CURREN_LANG) ?? 'en';

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(currentLang);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(currentLang);

    if ('th' === currentLang) {
      this.i18n.setLocale(th_TH);
    }
    else if ('vn' === currentLang) {
      this.i18n.setLocale(vi_VN);
    } else {
      this.i18n.setLocale(en_GB);
    }
  }

  async ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */


    if (!isNil(this.authService.getCurrentAD) && !window.location.pathname.includes('/login')) {
      this.msalBroadcastService.inProgress$
        .pipe(
          filter(
            (status: InteractionStatus) => status === InteractionStatus.None
          ),
          takeUntil(this._destroying$)
        )
        .subscribe(async () => {
          this.setLoginDisplay();

          this.accountService.apiAccountGet().subscribe((accRes) => {
            this.authService.setAccount(accRes);
          });
        });
    }
  }

  async setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  async login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.msalService.loginRedirect();
      }
    }
  }

  async logout() {
    this.msalService.logout();
  }

  changeLang(lang: string) {
    localStorage.setItem(CURREN_LANG, lang);

    this.translate.use(lang);
    if ('th' === lang) {
      this.i18n.setLocale(th_TH);
    } else {
      this.i18n.setLocale(en_GB);
    }
  }

  // unsubscribe to events when component is destroyed
  async ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
