/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ExportExcelFileRes } from '../models/export-excel-file-res';
import { StbActivityLogRes } from '../models/stb-activity-log-res';
import { StbInitRes } from '../models/stb-init-res';
import { StbOnPlanSaveExReq } from '../models/stb-on-plan-save-ex-req';
import { StbOnPlanSubmitExReq } from '../models/stb-on-plan-submit-ex-req';
import { StbRes } from '../models/stb-res';
import { StbVendorProductQueryReq } from '../models/stb-vendor-product-query-req';
import { StbVendorProductQueryRes } from '../models/stb-vendor-product-query-res';

@Injectable({ providedIn: 'root' })
export class SustainableWorksheetService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiSustainableWorksheetDataInitGet()` */
  static readonly ApiSustainableWorksheetDataInitGetPath = '/api/sustainable/worksheet/data-init';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetDataInitGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetDataInitGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StbInitRes>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetDataInitGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StbInitRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetDataInitGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetDataInitGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<StbInitRes> {
    return this.apiSustainableWorksheetDataInitGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<StbInitRes>): StbInitRes => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetGet()` */
  static readonly ApiSustainableWorksheetGetPath = '/api/sustainable/worksheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetGet$Response(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StbRes>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetGetPath, 'get');
    if (params) {
      rb.query('trnId', params.trnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StbRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetGet(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StbRes> {
    return this.apiSustainableWorksheetGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<StbRes>): StbRes => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetActivityLogsGet()` */
  static readonly ApiSustainableWorksheetActivityLogsGetPath = '/api/sustainable/worksheet/activity-logs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetActivityLogsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetActivityLogsGet$Response(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<StbActivityLogRes>>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetActivityLogsGetPath, 'get');
    if (params) {
      rb.query('trnId', params.trnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StbActivityLogRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetActivityLogsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetActivityLogsGet(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<Array<StbActivityLogRes>> {
    return this.apiSustainableWorksheetActivityLogsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StbActivityLogRes>>): Array<StbActivityLogRes> => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetOnPlanSavePost()` */
  static readonly ApiSustainableWorksheetOnPlanSavePostPath = '/api/sustainable/worksheet/on-plan/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetOnPlanSavePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetOnPlanSavePost$Response(
    params?: {
      trnId?: string;
      body?: StbOnPlanSaveExReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetOnPlanSavePostPath, 'post');
    if (params) {
      rb.query('trnId', params.trnId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetOnPlanSavePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetOnPlanSavePost(
    params?: {
      trnId?: string;
      body?: StbOnPlanSaveExReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiSustainableWorksheetOnPlanSavePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetOnPlanSubmitPost()` */
  static readonly ApiSustainableWorksheetOnPlanSubmitPostPath = '/api/sustainable/worksheet/on-plan/submit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetOnPlanSubmitPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetOnPlanSubmitPost$Response(
    params?: {
      body?: StbOnPlanSubmitExReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetOnPlanSubmitPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetOnPlanSubmitPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetOnPlanSubmitPost(
    params?: {
      body?: StbOnPlanSubmitExReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiSustainableWorksheetOnPlanSubmitPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetKpIsExportGet()` */
  static readonly ApiSustainableWorksheetKpIsExportGetPath = '/api/sustainable/worksheet/KPIs/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetKpIsExportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetKpIsExportGet$Response(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ExportExcelFileRes>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetKpIsExportGetPath, 'get');
    if (params) {
      rb.query('trnId', params.trnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExportExcelFileRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetKpIsExportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSustainableWorksheetKpIsExportGet(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<ExportExcelFileRes> {
    return this.apiSustainableWorksheetKpIsExportGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExportExcelFileRes>): ExportExcelFileRes => r.body)
    );
  }

  /** Path part for operation `apiSustainableWorksheetOptDataVendorProductsPost()` */
  static readonly ApiSustainableWorksheetOptDataVendorProductsPostPath = '/api/sustainable/worksheet/opt-data/vendor/products';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSustainableWorksheetOptDataVendorProductsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetOptDataVendorProductsPost$Response(
    params?: {
      body?: StbVendorProductQueryReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StbVendorProductQueryRes>> {
    const rb = new RequestBuilder(this.rootUrl, SustainableWorksheetService.ApiSustainableWorksheetOptDataVendorProductsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StbVendorProductQueryRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSustainableWorksheetOptDataVendorProductsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiSustainableWorksheetOptDataVendorProductsPost(
    params?: {
      body?: StbVendorProductQueryReq
    },
    context?: HttpContext
  ): Observable<StbVendorProductQueryRes> {
    return this.apiSustainableWorksheetOptDataVendorProductsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<StbVendorProductQueryRes>): StbVendorProductQueryRes => r.body)
    );
  }

}
