/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { DropdownItemRes } from '../models/dropdown-item-res';
import { MasterBankThPaymentRes } from '../models/master-bank-th-payment-res';
import { MasterBankThPaymentSearchReq } from '../models/master-bank-th-payment-search-req';
import { MasterBranchBankThRes } from '../models/master-branch-bank-th-res';
import { MasterBranchBankThSearchReq } from '../models/master-branch-bank-th-search-req';
import { MasterCountryRes } from '../models/master-country-res';
import { MasterCountrySearchReq } from '../models/master-country-search-req';
import { MasterGreenIndustryRes } from '../models/master-green-industry-res';
import { MasterVendorBranchRes } from '../models/master-vendor-branch-res';
import { MasterVendorBusinessStructureRes } from '../models/master-vendor-business-structure-res';
import { MasterVendorBusinessStructureSearchReq } from '../models/master-vendor-business-structure-search-req';
import { MasterVendorCompanyTypeRes } from '../models/master-vendor-company-type-res';
import { MasterVendorCompanyTypeSearchReq } from '../models/master-vendor-company-type-search-req';
import { MasterVendorContactTypeRes } from '../models/master-vendor-contact-type-res';
import { MasterVendorContactTypeSearchReq } from '../models/master-vendor-contact-type-search-req';
import { MasterVendorDocumentTypeRes } from '../models/master-vendor-document-type-res';
import { MasterVendorIsoCertificationRes } from '../models/master-vendor-iso-certification-res';
import { MasterVendorTypeRes } from '../models/master-vendor-type-res';
import { MasterVocTopicItem } from '../models/master-voc-topic-item';
import { MasterVocTopicSubItem } from '../models/master-voc-topic-sub-item';
import { VenUnspscOptDataRes } from '../models/ven-unspsc-opt-data-res';

@Injectable({ providedIn: 'root' })
export class MasterService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterCountriesGet()` */
  static readonly ApiMasterCountriesGetPath = '/api/master/countries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCountriesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCountriesGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCountryRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterCountriesGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCountryRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCountriesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCountriesGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterCountryRes>> {
    return this.apiMasterCountriesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCountryRes>>): Array<MasterCountryRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterCountrySearchPost()` */
  static readonly ApiMasterCountrySearchPostPath = '/api/master/country-search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCountrySearchPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCountrySearchPost$Response(
    params?: {
      body?: MasterCountrySearchReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCountryRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterCountrySearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCountryRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCountrySearchPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCountrySearchPost(
    params?: {
      body?: MasterCountrySearchReq
    },
    context?: HttpContext
  ): Observable<Array<MasterCountryRes>> {
    return this.apiMasterCountrySearchPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCountryRes>>): Array<MasterCountryRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterBusinessStructuresGet()` */
  static readonly ApiMasterBusinessStructuresGetPath = '/api/master/business-structures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterBusinessStructuresGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterBusinessStructuresGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorBusinessStructureRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterBusinessStructuresGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorBusinessStructureRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterBusinessStructuresGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterBusinessStructuresGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorBusinessStructureRes>> {
    return this.apiMasterBusinessStructuresGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorBusinessStructureRes>>): Array<MasterVendorBusinessStructureRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterBusinessStructureSearchPost()` */
  static readonly ApiMasterBusinessStructureSearchPostPath = '/api/master/business-structure-search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterBusinessStructureSearchPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterBusinessStructureSearchPost$Response(
    params?: {
      body?: MasterVendorBusinessStructureSearchReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorBusinessStructureRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterBusinessStructureSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorBusinessStructureRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterBusinessStructureSearchPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterBusinessStructureSearchPost(
    params?: {
      body?: MasterVendorBusinessStructureSearchReq
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorBusinessStructureRes>> {
    return this.apiMasterBusinessStructureSearchPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorBusinessStructureRes>>): Array<MasterVendorBusinessStructureRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterCompanyTypesGet()` */
  static readonly ApiMasterCompanyTypesGetPath = '/api/master/company-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCompanyTypesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCompanyTypesGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorCompanyTypeRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterCompanyTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorCompanyTypeRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCompanyTypesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCompanyTypesGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorCompanyTypeRes>> {
    return this.apiMasterCompanyTypesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorCompanyTypeRes>>): Array<MasterVendorCompanyTypeRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterCompanyTypeSearchPost()` */
  static readonly ApiMasterCompanyTypeSearchPostPath = '/api/master/company-type-search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCompanyTypeSearchPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCompanyTypeSearchPost$Response(
    params?: {
      body?: MasterVendorCompanyTypeSearchReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorCompanyTypeRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterCompanyTypeSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorCompanyTypeRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCompanyTypeSearchPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterCompanyTypeSearchPost(
    params?: {
      body?: MasterVendorCompanyTypeSearchReq
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorCompanyTypeRes>> {
    return this.apiMasterCompanyTypeSearchPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorCompanyTypeRes>>): Array<MasterVendorCompanyTypeRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterDocumentTypesGet()` */
  static readonly ApiMasterDocumentTypesGetPath = '/api/master/document-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDocumentTypesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDocumentTypesGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorDocumentTypeRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterDocumentTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorDocumentTypeRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDocumentTypesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDocumentTypesGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorDocumentTypeRes>> {
    return this.apiMasterDocumentTypesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorDocumentTypeRes>>): Array<MasterVendorDocumentTypeRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorTypesGet()` */
  static readonly ApiMasterVendorTypesGetPath = '/api/master/vendor-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorTypesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorTypesGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorTypeRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterVendorTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorTypeRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorTypesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorTypesGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorTypeRes>> {
    return this.apiMasterVendorTypesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorTypeRes>>): Array<MasterVendorTypeRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVendorBranchesGet()` */
  static readonly ApiMasterVendorBranchesGetPath = '/api/master/vendor-branches';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVendorBranchesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorBranchesGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorBranchRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterVendorBranchesGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorBranchRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVendorBranchesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVendorBranchesGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorBranchRes>> {
    return this.apiMasterVendorBranchesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorBranchRes>>): Array<MasterVendorBranchRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterContactTypesGet()` */
  static readonly ApiMasterContactTypesGetPath = '/api/master/contact-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterContactTypesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterContactTypesGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorContactTypeRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterContactTypesGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorContactTypeRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterContactTypesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterContactTypesGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorContactTypeRes>> {
    return this.apiMasterContactTypesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorContactTypeRes>>): Array<MasterVendorContactTypeRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterContactTypeSearchPost()` */
  static readonly ApiMasterContactTypeSearchPostPath = '/api/master/contact-type-search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterContactTypeSearchPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterContactTypeSearchPost$Response(
    params?: {
      body?: MasterVendorContactTypeSearchReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorContactTypeRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterContactTypeSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorContactTypeRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterContactTypeSearchPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterContactTypeSearchPost(
    params?: {
      body?: MasterVendorContactTypeSearchReq
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorContactTypeRes>> {
    return this.apiMasterContactTypeSearchPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorContactTypeRes>>): Array<MasterVendorContactTypeRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterBankPaymentGet()` */
  static readonly ApiMasterBankPaymentGetPath = '/api/master/bank-payment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterBankPaymentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterBankPaymentGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterBankThPaymentRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterBankPaymentGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterBankThPaymentRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterBankPaymentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterBankPaymentGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterBankThPaymentRes>> {
    return this.apiMasterBankPaymentGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterBankThPaymentRes>>): Array<MasterBankThPaymentRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterBankPaymentSearchPost()` */
  static readonly ApiMasterBankPaymentSearchPostPath = '/api/master/bank-payment-search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterBankPaymentSearchPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterBankPaymentSearchPost$Response(
    params?: {
      body?: MasterBankThPaymentSearchReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterBankThPaymentRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterBankPaymentSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterBankThPaymentRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterBankPaymentSearchPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterBankPaymentSearchPost(
    params?: {
      body?: MasterBankThPaymentSearchReq
    },
    context?: HttpContext
  ): Observable<Array<MasterBankThPaymentRes>> {
    return this.apiMasterBankPaymentSearchPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterBankThPaymentRes>>): Array<MasterBankThPaymentRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterBranchBankGet()` */
  static readonly ApiMasterBranchBankGetPath = '/api/master/branch-bank';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterBranchBankGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterBranchBankGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterBranchBankThRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterBranchBankGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterBranchBankThRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterBranchBankGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterBranchBankGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterBranchBankThRes>> {
    return this.apiMasterBranchBankGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterBranchBankThRes>>): Array<MasterBranchBankThRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterBranchBankSearchPost()` */
  static readonly ApiMasterBranchBankSearchPostPath = '/api/master/branch-bank-search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterBranchBankSearchPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterBranchBankSearchPost$Response(
    params?: {
      body?: MasterBranchBankThSearchReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterBranchBankThRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterBranchBankSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterBranchBankThRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterBranchBankSearchPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterBranchBankSearchPost(
    params?: {
      body?: MasterBranchBankThSearchReq
    },
    context?: HttpContext
  ): Observable<Array<MasterBranchBankThRes>> {
    return this.apiMasterBranchBankSearchPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterBranchBankThRes>>): Array<MasterBranchBankThRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterGreenIndustryGet()` */
  static readonly ApiMasterGreenIndustryGetPath = '/api/master/green-industry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGreenIndustryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGreenIndustryGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterGreenIndustryRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGreenIndustryGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterGreenIndustryRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterGreenIndustryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGreenIndustryGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterGreenIndustryRes>> {
    return this.apiMasterGreenIndustryGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterGreenIndustryRes>>): Array<MasterGreenIndustryRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterCertificateTypeGet()` */
  static readonly ApiMasterCertificateTypeGetPath = '/api/master/certificate-type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCertificateTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCertificateTypeGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVendorIsoCertificationRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterCertificateTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVendorIsoCertificationRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCertificateTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCertificateTypeGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVendorIsoCertificationRes>> {
    return this.apiMasterCertificateTypeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVendorIsoCertificationRes>>): Array<MasterVendorIsoCertificationRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterUnspscContainGet()` */
  static readonly ApiMasterUnspscContainGetPath = '/api/master/unspsc-contain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterUnspscContainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterUnspscContainGet$Response(
    params?: {
      keyword?: string;
      max?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VenUnspscOptDataRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterUnspscContainGetPath, 'get');
    if (params) {
      rb.query('keyword', params.keyword, {});
      rb.query('max', params.max, {});
      rb.query('workGroupIds', params.workGroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VenUnspscOptDataRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterUnspscContainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterUnspscContainGet(
    params?: {
      keyword?: string;
      max?: number;
      workGroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<VenUnspscOptDataRes>> {
    return this.apiMasterUnspscContainGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VenUnspscOptDataRes>>): Array<VenUnspscOptDataRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVocTopicItemsGet()` */
  static readonly ApiMasterVocTopicItemsGetPath = '/api/master/voc-topic-items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVocTopicItemsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocTopicItemsGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVocTopicItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterVocTopicItemsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVocTopicItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVocTopicItemsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocTopicItemsGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVocTopicItem>> {
    return this.apiMasterVocTopicItemsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVocTopicItem>>): Array<MasterVocTopicItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterCurrencyGet()` */
  static readonly ApiMasterCurrencyGetPath = '/api/master/currency';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterCurrencyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCurrencyGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterCurrencyGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterCurrencyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterCurrencyGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.apiMasterCurrencyGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiMasterProvinceThaiGet()` */
  static readonly ApiMasterProvinceThaiGetPath = '/api/master/province-thai';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterProvinceThaiGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterProvinceThaiGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<DropdownItemRes>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterProvinceThaiGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DropdownItemRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterProvinceThaiGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterProvinceThaiGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<DropdownItemRes>> {
    return this.apiMasterProvinceThaiGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DropdownItemRes>>): Array<DropdownItemRes> => r.body)
    );
  }

  /** Path part for operation `apiMasterVocTopicSubItemsGet()` */
  static readonly ApiMasterVocTopicSubItemsGetPath = '/api/master/voc-topic-sub-items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterVocTopicSubItemsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocTopicSubItemsGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterVocTopicSubItem>>> {
    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterVocTopicSubItemsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterVocTopicSubItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterVocTopicSubItemsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterVocTopicSubItemsGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<MasterVocTopicSubItem>> {
    return this.apiMasterVocTopicSubItemsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterVocTopicSubItem>>): Array<MasterVocTopicSubItem> => r.body)
    );
  }

}
