/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HcDashboardDropdownExItem } from '../models/hc-dashboard-dropdown-ex-item';
import { HcDashboardQueryExReq } from '../models/hc-dashboard-query-ex-req';
import { HcDashboardResultExRes } from '../models/hc-dashboard-result-ex-res';
import { MasterCategoryAndSeverityResItem } from '../models/master-category-and-severity-res-item';

@Injectable({ providedIn: 'root' })
export class HealthCheckDashboardService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiHealthCheckDashboardQueryPost()` */
  static readonly ApiHealthCheckDashboardQueryPostPath = '/api/health-check/dashboard/query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckDashboardQueryPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckDashboardQueryPost$Response(
    params?: {
      body?: HcDashboardQueryExReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcDashboardResultExRes>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckDashboardService.ApiHealthCheckDashboardQueryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcDashboardResultExRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckDashboardQueryPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckDashboardQueryPost(
    params?: {
      body?: HcDashboardQueryExReq
    },
    context?: HttpContext
  ): Observable<HcDashboardResultExRes> {
    return this.apiHealthCheckDashboardQueryPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcDashboardResultExRes>): HcDashboardResultExRes => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckDashboardMasterCategorySeverityContainPost()` */
  static readonly ApiHealthCheckDashboardMasterCategorySeverityContainPostPath = '/api/health-check/dashboard/master-category-severity-contain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckDashboardMasterCategorySeverityContainPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckDashboardMasterCategorySeverityContainPost$Response(
    params?: {
      containText?: string;
      max?: number;
      body?: Array<number>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCategoryAndSeverityResItem>>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckDashboardService.ApiHealthCheckDashboardMasterCategorySeverityContainPostPath, 'post');
    if (params) {
      rb.query('containText', params.containText, {});
      rb.query('max', params.max, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCategoryAndSeverityResItem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckDashboardMasterCategorySeverityContainPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiHealthCheckDashboardMasterCategorySeverityContainPost(
    params?: {
      containText?: string;
      max?: number;
      body?: Array<number>
    },
    context?: HttpContext
  ): Observable<Array<MasterCategoryAndSeverityResItem>> {
    return this.apiHealthCheckDashboardMasterCategorySeverityContainPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCategoryAndSeverityResItem>>): Array<MasterCategoryAndSeverityResItem> => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckDashboardDdlListGet()` */
  static readonly ApiHealthCheckDashboardDdlListGetPath = '/api/health-check/dashboard/ddl-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHealthCheckDashboardDdlListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckDashboardDdlListGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HcDashboardDropdownExItem>> {
    const rb = new RequestBuilder(this.rootUrl, HealthCheckDashboardService.ApiHealthCheckDashboardDdlListGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HcDashboardDropdownExItem>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHealthCheckDashboardDdlListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHealthCheckDashboardDdlListGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<HcDashboardDropdownExItem> {
    return this.apiHealthCheckDashboardDdlListGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<HcDashboardDropdownExItem>): HcDashboardDropdownExItem => r.body)
    );
  }

}
