/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AdActivityLogRes } from '../models/ad-activity-log-res';
import { AdExRes } from '../models/ad-ex-res';
import { AuditTrnChatReq } from '../models/audit-trn-chat-req';
import { AuditTrnChatRes } from '../models/audit-trn-chat-res';

@Injectable({ providedIn: 'root' })
export class AuditWorksheetService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAuditWorksheetGet()` */
  static readonly ApiAuditWorksheetGetPath = '/api/audit/worksheet';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditWorksheetGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditWorksheetGet$Response(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AdExRes>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditWorksheetGetPath, 'get');
    if (params) {
      rb.query('trnId', params.trnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdExRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditWorksheetGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditWorksheetGet(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<AdExRes> {
    return this.apiAuditWorksheetGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdExRes>): AdExRes => r.body)
    );
  }

  /** Path part for operation `apiAuditWorksheetActivityLogsGet()` */
  static readonly ApiAuditWorksheetActivityLogsGetPath = '/api/audit/worksheet/activity-logs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditWorksheetActivityLogsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditWorksheetActivityLogsGet$Response(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AdActivityLogRes>>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditWorksheetActivityLogsGetPath, 'get');
    if (params) {
      rb.query('trnId', params.trnId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdActivityLogRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditWorksheetActivityLogsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditWorksheetActivityLogsGet(
    params?: {
      trnId?: string;
    },
    context?: HttpContext
  ): Observable<Array<AdActivityLogRes>> {
    return this.apiAuditWorksheetActivityLogsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdActivityLogRes>>): Array<AdActivityLogRes> => r.body)
    );
  }

  /** Path part for operation `apiAuditWorksheetChatMessagesGet()` */
  static readonly ApiAuditWorksheetChatMessagesGetPath = '/api/audit/worksheet/chat/messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditWorksheetChatMessagesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditWorksheetChatMessagesGet$Response(
    params?: {
      refId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AuditTrnChatRes>>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditWorksheetChatMessagesGetPath, 'get');
    if (params) {
      rb.query('refId', params.refId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AuditTrnChatRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditWorksheetChatMessagesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditWorksheetChatMessagesGet(
    params?: {
      refId?: string;
    },
    context?: HttpContext
  ): Observable<Array<AuditTrnChatRes>> {
    return this.apiAuditWorksheetChatMessagesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AuditTrnChatRes>>): Array<AuditTrnChatRes> => r.body)
    );
  }

  /** Path part for operation `apiAuditWorksheetChatMessagesDelete()` */
  static readonly ApiAuditWorksheetChatMessagesDeletePath = '/api/audit/worksheet/chat/messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditWorksheetChatMessagesDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditWorksheetChatMessagesDelete$Response(
    params?: {
      chatId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditWorksheetChatMessagesDeletePath, 'delete');
    if (params) {
      rb.query('chatId', params.chatId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditWorksheetChatMessagesDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditWorksheetChatMessagesDelete(
    params?: {
      chatId?: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAuditWorksheetChatMessagesDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiAuditWorksheetChatMessagePost()` */
  static readonly ApiAuditWorksheetChatMessagePostPath = '/api/audit/worksheet/chat/message';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditWorksheetChatMessagePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditWorksheetChatMessagePost$Response(
    params?: {
      body?: AuditTrnChatReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuditWorksheetService.ApiAuditWorksheetChatMessagePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditWorksheetChatMessagePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiAuditWorksheetChatMessagePost(
    params?: {
      body?: AuditTrnChatReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAuditWorksheetChatMessagePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
