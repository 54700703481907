/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountService } from './services/account.service';
import { AccountSettingService } from './services/account-setting.service';
import { AnnouncementService } from './services/announcement.service';
import { AppService } from './services/app.service';
import { AuditDashboardService } from './services/audit-dashboard.service';
import { AuditWorksheetService } from './services/audit-worksheet.service';
import { CcrRequestService } from './services/ccr-request.service';
import { CcrRequestFileService } from './services/ccr-request-file.service';
import { CcrWorklistService } from './services/ccr-worklist.service';
import { FaqService } from './services/faq.service';
import { FileService } from './services/file.service';
import { FileApiService } from './services/file-api.service';
import { HealthCheckDashboardService } from './services/health-check-dashboard.service';
import { HealthCheckWorksheetService } from './services/health-check-worksheet.service';
import { HomeService } from './services/home.service';
import { InboxService } from './services/inbox.service';
import { ManualRequestService } from './services/manual-request.service';
import { MasterService } from './services/master.service';
import { ServerService } from './services/server.service';
import { SustainableDashboardService } from './services/sustainable-dashboard.service';
import { SustainableWorksheetService } from './services/sustainable-worksheet.service';
import { VendorContactInfoService } from './services/vendor-contact-info.service';
import { VendorProfileService } from './services/vendor-profile.service';
import { VocExtRequestService } from './services/voc-ext-request.service';
import { VocExtWorklistService } from './services/voc-ext-worklist.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountService,
    AccountSettingService,
    AnnouncementService,
    AppService,
    AuditDashboardService,
    AuditWorksheetService,
    CcrRequestService,
    CcrRequestFileService,
    CcrWorklistService,
    FaqService,
    FileService,
    FileApiService,
    HealthCheckDashboardService,
    HealthCheckWorksheetService,
    HomeService,
    InboxService,
    ManualRequestService,
    MasterService,
    ServerService,
    SustainableDashboardService,
    SustainableWorksheetService,
    VendorContactInfoService,
    VendorProfileService,
    VocExtRequestService,
    VocExtWorklistService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
