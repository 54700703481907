/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { VendorContactInfoReq } from '../models/vendor-contact-info-req';
import { VendorContactInfoRes } from '../models/vendor-contact-info-res';

@Injectable({ providedIn: 'root' })
export class VendorContactInfoService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiVendorContactInfoByIdGet()` */
  static readonly ApiVendorContactInfoByIdGetPath = '/api/vendor-contact-info/by-id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorContactInfoByIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorContactInfoByIdGet$Response(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<VendorContactInfoRes>> {
    const rb = new RequestBuilder(this.rootUrl, VendorContactInfoService.ApiVendorContactInfoByIdGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VendorContactInfoRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorContactInfoByIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorContactInfoByIdGet(
    params?: {
      id?: string;
    },
    context?: HttpContext
  ): Observable<VendorContactInfoRes> {
    return this.apiVendorContactInfoByIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<VendorContactInfoRes>): VendorContactInfoRes => r.body)
    );
  }

  /** Path part for operation `apiVendorContactInfoCreatePost()` */
  static readonly ApiVendorContactInfoCreatePostPath = '/api/vendor-contact-info/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorContactInfoCreatePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorContactInfoCreatePost$Response(
    params?: {
      body?: VendorContactInfoReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, VendorContactInfoService.ApiVendorContactInfoCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorContactInfoCreatePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorContactInfoCreatePost(
    params?: {
      body?: VendorContactInfoReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiVendorContactInfoCreatePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiVendorContactInfoUpdatePatch()` */
  static readonly ApiVendorContactInfoUpdatePatchPath = '/api/vendor-contact-info/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorContactInfoUpdatePatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorContactInfoUpdatePatch$Response(
    params?: {
      body?: VendorContactInfoReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, VendorContactInfoService.ApiVendorContactInfoUpdatePatchPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorContactInfoUpdatePatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorContactInfoUpdatePatch(
    params?: {
      body?: VendorContactInfoReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiVendorContactInfoUpdatePatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
