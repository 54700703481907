/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AppDataExRes } from '../models/app-data-ex-res';
import { WorkGroupLabelRes } from '../models/work-group-label-res';

@Injectable({ providedIn: 'root' })
export class AppService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAppDataGet()` */
  static readonly ApiAppDataGetPath = '/api/app/data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppDataGet$Response(
    params?: {
      config?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AppDataExRes>> {
    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppDataGetPath, 'get');
    if (params) {
      rb.query('config', params.config, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AppDataExRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppDataGet(
    params?: {
      config?: string;
    },
    context?: HttpContext
  ): Observable<AppDataExRes> {
    return this.apiAppDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AppDataExRes>): AppDataExRes => r.body)
    );
  }

  /** Path part for operation `apiAppWorkgroupLabelGet()` */
  static readonly ApiAppWorkgroupLabelGetPath = '/api/app/workgroup-label';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppWorkgroupLabelGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppWorkgroupLabelGet$Response(
    params?: {
      fkWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WorkGroupLabelRes>> {
    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppWorkgroupLabelGetPath, 'get');
    if (params) {
      rb.query('fkWorkGroupId', params.fkWorkGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkGroupLabelRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppWorkgroupLabelGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppWorkgroupLabelGet(
    params?: {
      fkWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<WorkGroupLabelRes> {
    return this.apiAppWorkgroupLabelGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkGroupLabelRes>): WorkGroupLabelRes => r.body)
    );
  }

  /** Path part for operation `apiAppReloadConfsGet()` */
  static readonly ApiAppReloadConfsGetPath = '/api/app/reload-confs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppReloadConfsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppReloadConfsGet$Response(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppReloadConfsGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppReloadConfsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppReloadConfsGet(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiAppReloadConfsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiAppReloadDefaultEmailTemplatesGet()` */
  static readonly ApiAppReloadDefaultEmailTemplatesGetPath = '/api/app/reload-default-email-templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppReloadDefaultEmailTemplatesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppReloadDefaultEmailTemplatesGet$Response(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppReloadDefaultEmailTemplatesGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppReloadDefaultEmailTemplatesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppReloadDefaultEmailTemplatesGet(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiAppReloadDefaultEmailTemplatesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiAppTryConnectAzureB2CGet()` */
  static readonly ApiAppTryConnectAzureB2CGetPath = '/api/app/try-connect-azure-b2c';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppTryConnectAzureB2CGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppTryConnectAzureB2CGet$Response(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppTryConnectAzureB2CGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppTryConnectAzureB2CGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppTryConnectAzureB2CGet(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiAppTryConnectAzureB2CGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiAppExpireTagGet()` */
  static readonly ApiAppExpireTagGetPath = '/api/app/ExpireTag';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppExpireTagGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppExpireTagGet$Response(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AppService.ApiAppExpireTagGetPath, 'get');
    if (params) {
      rb.query('secret', params.secret, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppExpireTagGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppExpireTagGet(
    params?: {
      secret?: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiAppExpireTagGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
